import React from 'react';
import './SimpleNewsletterForm.scss';
import { getStringValueBySegment, NewsletterSegment, ValueType } from 'model/freeUpdates';
import { getFormOptions } from 'model/convert-kit-form-options';

interface SimpleNewsletterFormProps {
  segment: NewsletterSegment;
}

const SimpleNewsletterForm: React.FC<SimpleNewsletterFormProps> = (props: SimpleNewsletterFormProps) => {
  return (
    <form
      action={`https://app.convertkit.com/forms/${getStringValueBySegment(
        ValueType.FormSV,
        props.segment
      )}/subscriptions`}
      className="subscribe-form"
      method="post"
      data-sv-form={getStringValueBySegment(ValueType.FormSV, props.segment)}
      data-uid={getStringValueBySegment(ValueType.FormUids, props.segment)}
      data-format="inline"
      data-version="5"
      data-options={JSON.stringify(getFormOptions())}
    >
      <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert" />
      <div className="formkit-field form-group">
        <input
          className="formkit-input form-control"
          aria-label="Your first name"
          name="fields[first_name]"
          placeholder="Your first name"
          type="text"
        />
      </div>
      <div className="formkit-field form-group">
        <input
          className="formkit-input form-control"
          name="email_address"
          placeholder="Your email address"
          required
          type="email"
        />
      </div>
      <button type="submit" data-element="submit" className="formkit-submit formkit-submit btn btn-primary">
        <div className="formkit-spinner">
          <div />
          <div />
          <div />
        </div>
        <span>Subscribe</span>
      </button>
    </form>
  );
};

export { SimpleNewsletterForm };
