import React, { ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import SeoHelmet from '../SeoHelmet';
import 'bootstrap/dist/css/bootstrap.css';

import Header from '../Header';
import Footer from '../Footer';
import './Layout.scss';

interface LayoutProps {
  children: ReactNode;
  className?: string;
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => (
  <CookiesProvider>
    <div className={props.className}>
      <SeoHelmet />
      <div className="page">
        <Header />
        {props.children}
        <Footer />
      </div>
    </div>
  </CookiesProvider>
);

Layout.defaultProps = {
  className: '',
};

export default Layout;
