const formOptions = {
  settings: {
    after_subscribe: {
      action: 'message',
      success_message: 'Awesome! Check your email to confirm your subscription.',
      redirect_url: '',
    },
    analytics: { google: null, facebook: null, segment: null, pinterest: null },
    modal: {},
    powered_by: {
      show: false,
      url: '',
    },
    recaptcha: { enabled: false },
    return_visitor: { action: 'show', custom_content: '' },
    slide_in: {},
    sticky_bar: {},
  },
  version: '5',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormOptions = (): any => formOptions;
