import React from 'react';

export interface SeoContextArgs {
  title: string;
  featuredImagePath?: string;
  width?: number;
  height?: number;
}

export const seoContext = React.createContext<SeoContextArgs>({
  title: '',
});
