import React from 'react';
import './Newsletter.scss';
import { NewsletterSegment } from 'model/freeUpdates';
import { SimpleNewsletterForm } from 'components/SimpleNewsletterForm';

interface NewsletterProps {
  id: string;
}

const Newsletter: React.FC<NewsletterProps> = (props) => {
  return (
    <>
      <div className="newsletter" id={props.id}>
        <div className="container">
          <h3>Sign up for my newsletter!</h3>
          <div className="subscribe">
            <SimpleNewsletterForm segment={NewsletterSegment.BottomForm} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
