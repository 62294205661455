import queryString from 'query-string';

// I'm almost sure this is a false positive
// eslint-disable-next-line no-shadow
export enum NewsletterSegment {
  Default = '',
  YoutubeJsStrings = 'youtube-js-strings',
  InterviewProcessDiagram = 'interview-process-diagram',

  // The random string at the end is a temporary measure. The way the system
  // is setup, a 'free updates' page could be generated for these enum values
  // I don't think it would be a problem if someone happens to find it
  // but I've decided to make the strings harder to find just in case.
  // Eventually, it might be a good idea to separate the enums that
  // will get a page and the enums that won't get a page, but I don't think
  // it's a priority right now
  BottomForm = 'bottom-form-q27GTMbXg2kEJp8',
  ArticlePopUp = 'article-pop-up-q27GTMbXg2kEJp8',
}

// I'm almost sure this is a false positive
// eslint-disable-next-line no-shadow
export enum ValueType {
  Title,
  FormSV,
  FormUids,
}

// -------------------------------------------------------------------
const titles: Map<NewsletterSegment, string> = new Map();
titles.set(NewsletterSegment.YoutubeJsStrings, 'Free Updates');
titles.set(NewsletterSegment.InterviewProcessDiagram, "Download Cadejo.dev's Code Challenge Interview Process");

titles.set(NewsletterSegment.BottomForm, 'Free Updates');
titles.set(NewsletterSegment.ArticlePopUp, 'Free Updates');

titles.set(NewsletterSegment.Default, 'Free Updates');
// -------------------------------------------------------------------
const formSVs: Map<NewsletterSegment, string> = new Map();
formSVs.set(NewsletterSegment.YoutubeJsStrings, '1799609');
formSVs.set(NewsletterSegment.InterviewProcessDiagram, '2239883');

formSVs.set(NewsletterSegment.BottomForm, '1537235');
formSVs.set(NewsletterSegment.ArticlePopUp, '1799611');

formSVs.set(NewsletterSegment.Default, '1799611');
// -------------------------------------------------------------------
const formUids: Map<NewsletterSegment, string> = new Map();
formUids.set(NewsletterSegment.YoutubeJsStrings, '85c8c196c0');
formUids.set(NewsletterSegment.InterviewProcessDiagram, 'f89cd136ee');

formUids.set(NewsletterSegment.BottomForm, '91ade4734a');
formUids.set(NewsletterSegment.ArticlePopUp, '1d443e5575');

formUids.set(NewsletterSegment.Default, '1d443e5575');
// -------------------------------------------------------------------

export const getStringValueBySegment = (type: ValueType, segment: NewsletterSegment): string => {
  let result: string | undefined;

  switch (type) {
    case ValueType.Title:
      result = titles.get(segment);
      break;
    case ValueType.FormSV:
      result = formSVs.get(segment);
      break;
    case ValueType.FormUids:
      result = formUids.get(segment);
      break;
    default:
      result = '';
  }

  return result || '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNewsletterSegment = (test: any): test is NewsletterSegment => {
  return Object.values(NewsletterSegment).indexOf(test) !== -1;
};

const convertValidatedParsedSourceToEnum = (rawSegment: string): NewsletterSegment => {
  if (isNewsletterSegment(rawSegment)) {
    return rawSegment as NewsletterSegment;
  }

  return NewsletterSegment.Default;
};

export const getNewsletterSegmentFromURL = (search: string): NewsletterSegment => {
  if (!search) {
    return NewsletterSegment.Default;
  }
  const parsedSearch = queryString.parse(search);

  if (!parsedSearch.source || Array.isArray(parsedSearch.source)) {
    return NewsletterSegment.Default;
  }
  return convertValidatedParsedSourceToEnum(parsedSearch.source);
};
