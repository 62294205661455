// --------------------------------------------------------
// 3rd Party Modules
// --------------------------------------------------------
import { Link } from 'gatsby';
import React from 'react';
// --------------------------------------------------------
// Custom Modules
// --------------------------------------------------------
import { HOME_ROUTE, ABOUT_ROUTE, CONTACT_ROUTE } from 'commonRoutes';
import Newsletter from '../Newsletter';
import './Footer.scss';
// --------------------------------------------------------

const Footer: React.FC = () => (
  <footer>
    <div className="menu">
      <div className="container">
        <div className="menu-list">
          <Link to={HOME_ROUTE} className="menu-link">
            Home
          </Link>
          <Link to={ABOUT_ROUTE} className="menu-link">
            About
          </Link>
          <Link to={CONTACT_ROUTE} className="menu-link">
            Contact
          </Link>
        </div>
      </div>
    </div>
    <div className="footer">
      <Newsletter id="footer-newsletter" />
      <nav className="footer-nav">
        <Link to={HOME_ROUTE} className="footer-link">
          HOME
        </Link>
        <Link to={ABOUT_ROUTE} className="footer-link">
          ABOUT
        </Link>
        <Link to={CONTACT_ROUTE} className="footer-link">
          CONTACT
        </Link>
      </nav>
    </div>
  </footer>
);

export default Footer;
