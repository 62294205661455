import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { SeoContextArgs, seoContext } from 'context/seo-context';
import assert from 'assert';
import { OptInModal, optInModalContext, OptInModalContextArgs } from 'context/opt-in-modal-context';

const getOptionalOptInModalCode = (modal: OptInModal) => {
  switch (modal) {
    case OptInModal.None:
    default:
      return <script src="https://f.convertkit.com/ckjs/ck.5.js" />;
  }
};

const getFeaturedImageMetadata = (seoContextValues: SeoContextArgs): Record<string, string>[] => {
  const featuredImageFullPath = `http://cadejo.dev${seoContextValues.featuredImagePath}`;

  if (seoContextValues.featuredImagePath && seoContextValues.width && seoContextValues.height) {
    return [
      { property: 'og:image', content: featuredImageFullPath },
      { property: 'og:image:width', content: seoContextValues.width.toString() },
      { property: 'og:image:height ', content: seoContextValues.height.toString() },
      { name: 'twitter:image', content: featuredImageFullPath },
    ];
  }

  return [];
};

const getMetadata = (seoContextValues: SeoContextArgs) => {
  const title = `${seoContextValues.title} | Cadejo.dev`;

  const baseMetadata: Record<string, string>[] = [
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'twitter:creator', content: 'Cadejo_Dev' },
    { name: 'twitter:title', content: title },
  ];

  const featuredImageMetadata = getFeaturedImageMetadata(seoContextValues);

  return baseMetadata.concat(featuredImageMetadata);
};

const SeoHelmet: React.FC = () => {
  const seoContextValues = useContext<SeoContextArgs>(seoContext);
  const optInModalContextValues = useContext<OptInModalContextArgs>(optInModalContext);
  // ------------------------------------------------------------
  assert(seoContextValues.title !== '');
  // ------------------------------------------------------------
  const optionalOptInModal = getOptionalOptInModalCode(optInModalContextValues.form);
  const meta = getMetadata(seoContextValues);

  return (
    <Helmet htmlAttributes={{ lang: 'en' }} title={seoContextValues.title} titleTemplate="%s | Cadejo.dev" meta={meta}>
      <link href="https://fonts.googleapis.com/css?family=PT+Serif&display=swap" rel="stylesheet" />
      {optionalOptInModal}
    </Helmet>
  );
};

export default SeoHelmet;
