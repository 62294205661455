// ------------------------------------------------------------------------------------------------
// 3rd Party Modules
// ------------------------------------------------------------------------------------------------
import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
// ------------------------------------------------------------------------------------------------
// Custom Modules
// ------------------------------------------------------------------------------------------------
import './Brand.scss';
// ------------------------------------------------------------------------------------------------

const Brand: React.FC = () => {
  const data = useStaticQuery(graphql`
    query BrandQuery {
      file(relativePath: { eq: "header-logo.png" }) {
        childImageSharp {
          fixed(width: 250, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <div className="navbar-brand">
      <Link to="/">
        <Img fixed={data.file.childImageSharp.fixed} alt="Cadejo.dev" />
      </Link>
    </div>
  );
};

export default Brand;
