import React from 'react';

// I'm almost sure this is a false positive
// eslint-disable-next-line no-shadow
export enum OptInModal {
  None,
  ArticleDefault,
}

export interface OptInModalContextArgs {
  form: OptInModal;
}

export const optInModalContext = React.createContext<OptInModalContextArgs>({ form: OptInModal.None });
