// ------------------------------------------------------------------------------------------------
// 3rd Party Modules
// ------------------------------------------------------------------------------------------------
import React from 'react';
import { Link } from 'gatsby';
// ------------------------------------------------------------------------------------------------
// Custom Modules
// ------------------------------------------------------------------------------------------------
import { HOME_ROUTE, ABOUT_ROUTE, CONTACT_ROUTE } from 'commonRoutes';
import Brand from 'components/Header/Brand';
import './Header.scss';
// ------------------------------------------------------------------------------------------------

const Header: React.FC = () => (
  <>
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Brand />
          <div className="navbar-collapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to={HOME_ROUTE} className="nav-link">
                  HOME
                </Link>
              </li>

              <li className="nav-item">
                <Link to={ABOUT_ROUTE} className="nav-link">
                  ABOUT
                </Link>
              </li>

              <li className="nav-item">
                <Link to={CONTACT_ROUTE} className="nav-link">
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </>
);

export default Header;
